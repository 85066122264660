


























import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import availableProccesses, { Process } from '@/utils/availableProcesses';
import TokenService from '@/services/TokenService';

const tokenService = new TokenService();

export default Vue.extend({
  name: 'ApplicationSwitcher',
  data() {
    return {
      apps: [] as Array<Process>,
    };
  },
  computed: {
    ...mapState('globals', ['token']),
    marginCard(): string {
      return this.$vuetify.breakpoint.width < 370 ? 'mx-1' : 'mx-3';
    },
    cardSize(): string {
      return this.$vuetify.breakpoint.width < 400 ? '64' : '80';
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PROCESSES']),
    processes(): void {
      const rules = tokenService.userRoles(this.token);
      this.apps = availableProccesses(rules);
      this.SET_PROCESSES(this.apps);
    },
  },
  created() {
    this.processes();
  },
});
