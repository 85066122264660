/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */

export interface Process {
  name: string;
  routePath: string;
  iconName: string;
  usersPermission: Array<string>;
}

const orderProcess: Process = {
  name: 'orders',
  routePath: '/orders/',
  iconName: 'orderDeliveryIcon',
  usersPermission: ['ROLE_MERCHANT_ADMIN', 'ROLE_MERCHANT_USER'],
};

const returnProcess: Process = {
  name: 'return',
  routePath: '/returns/',
  iconName: 'boxReturnIcon',
  usersPermission: ['ROLE_MERCHANT_ADMIN', 'ROLE_MERCHANT_USER'],
};

const boxOverviewProcess: Process = {
  name: 'boxoverview',
  routePath: '/box-overview',
  iconName: 'boxOverviewIcon',
  usersPermission: ['ROLE_REBOX_ADMIN', 'ROLE_MERCHANT_USER', 'ROLE_MERCHANT_ADMIN'],
};

const configurationProcess: Process = {
  name: 'configuration',
  routePath: '/configuration/',
  iconName: 'configurationIcon',
  usersPermission: ['ROLE_MERCHANT_ADMIN'],
};

const importOrdersProcess: Process = {
  name: 'import',
  routePath: '/orders-import',
  iconName: 'orderImportIcon',
  usersPermission: ['ROLE_MERCHANT_USER'],
};

const userManagementProcess: Process = {
  name: 'usermanagement',
  routePath: '/user-management',
  iconName: 'userManagementIcon',
  usersPermission: ['ROLE_REBOX_ADMIN', 'ROLE_MERCHANT_ADMIN'],
};

const boxRegistrationProcess: Process = {
  name: 'boxregistration',
  routePath: '/registration',
  iconName: 'boxRegistrationIcon',
  usersPermission: ['ROLE_MERCHANT_ADMIN'],
};

// TODO
// OrderImportProcess -> new process
// ROLES: ROLE_MERCHANT_ADMIN, ROLE_MERCHANT_USER

// AFTER MVP
// ADMIN-CONFIGURATION -> new process

const allProcesses: Array<Process> = [
  configurationProcess,
  boxRegistrationProcess,
  boxOverviewProcess,
  importOrdersProcess,
  orderProcess,
  returnProcess,
  userManagementProcess,
];

function getProcesses(userRole: string[]): Array<Process> {
  const processes = allProcesses.filter((process) => {
    const { usersPermission } = process;
    const contains = usersPermission.some((permission) => userRole.indexOf(permission) >= 0);
    return contains && process;
  });
  return processes;
}

// userRoles have to be returned from TokenService.userRoles
function availableProcesses(userRoles: Array<string>): Array<Process> {
  const roles = getProcesses(userRoles);
  return roles;
}

export default availableProcesses;
